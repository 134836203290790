<template>
  <div class="text-white table-responsive">
    <h1 class="text-white text-center text-3xl mb-3" >Minhas músicas</h1>
    
    <div class="hr2" style="margin-top: 12px"></div>
    
    <br />
    <router-link @click="onClickMenu" to="/nova" class="btn btn-block btn-registrar mb-2 text-black font-bold">
      {{ $t("appHeader.registro-musicas") }}
    </router-link>
  <div v-if="musicasSemFiltro.length==0">
    <p>Você não possui nenhuma música registrada.</p>
  </div>
  <div v-else>
    <div class="mx-3 d-sm-flex flex-sm-row justify-content-sm-evenly align-items-sm-center mb-4 mx-sm-0">
      <div class="d-flex ">
        <input type="radio" name="filtro" id="todos" value="1" class="w-5 h-5 mr-1" v-model="radioFiltro" @change="filtrarTabela">
        <label for="todos" class="text-white bg-transparent font-medium">Todos</label>
      </div>
      <div class="d-flex ">
        <input type="radio" name="filtro" id="pendente" value="2" class="w-5 h-5 mr-1" v-model="radioFiltro" @change="filtrarTabela">
        <label for="pendente" class="text-white bg-transparent font-medium ">Pagamento Pendente</label>
      </div>
      <!-- <div class="d-flex">
        <input type="radio" name="filtro" id="finalizados" value="3" class="w-5 h-5 mr-1" v-model="radioFiltro" @change="filtrarTabela">
        <label for="finalizados" class="text-white bg-transparent font-medium">Disponíveis</label>
      </div> -->
    </div>

  <div class="input-group mb-3">
    <span class="input-group-text icon-search" id="searchByName">
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
    <input type="text" id="searchTable" v-model="filtroPorNome" class="form-control fs-5" @keyup="filterByName()" placeholder="Pesquisar pelo nome.." aria-label="Username" aria-describedby="searchByName" title="Digite o nome da música.">
  </div>
    
  <table class="table-auto w-full mb-5" id="tabelaMusicas">
  <thead class="bg-primary text-black">
    <tr>
      <th  class="w-1/7 px-4 py-2 text-center">#</th>
      <th  class="w-1/2 px-4 py-2 text-center">Nome</th>
      <th  class="w-1/2 px-4 py-2 text-center">Status</th>
      <th  class="w-1/3 px-4 py-2 text-center">Ações</th>
    </tr>
  </thead>
  <tbody>
    <tr class="text-white" v-for="(music, index) in musics" :key="index">
      <th class="border text-center py-2" >{{ index }}</th>
      <td class="border text-center py-2"> {{ music.name }} </td>
      <td class="border text-center py-2" > {{ music.status.name }} </td>
      <td class="border text-center py-2" v-if="music.status.id==1">
        <div class="btn-text" @click="abrirModalPix(music)">
            Efetuar Pagamento
        </div>
      </td>
      <td class="border px-4 py-2" v-else>
        <div class="btn-text text-center" @click="verDetalhes(music.id)">
            Ver Detalhes
        </div>
      </td>
    </tr>
    
  </tbody>
</table> 
</div>
    <ModalPix :capa="asset.thumbnail" :title="asset.name" :asset="asset" @close="atualizarTabela" :show="m" v-if="m" :tipo="'registroMusica'"/>
  </div>
</template>

<script>
import api from '@/api'
import router from '@/router';
import ModalPix from '../../../components/shared/ModalPix.vue';
export default {
  name: "MyMusics",
  data() {
    return {
      musics: [],
      musicasSemFiltro: [],
      m: false,
      asset: [],
      radioFiltro: '1',
      filtroPorNome: '',
    };
  },
  created() {
    this.getMusics();
  },
  methods: {
    async getMusics() {
    await api.get("my-musics").then( async response => {
        this.musicasSemFiltro = response;
        this.musics = response;
    }).catch(error => {
        console.error(error)
    })
    },
    verDetalhes(id) {
        router.push({ name: 'Music Details', params: { id: id } })
    },
    abrirModalPix(asset) {
        this.asset = [];
        this.asset = asset;
        this.m = true;
    },
    atualizarTabela() {
        this.m = false
        this.getMusics()
    },
    filtrarTabela() {
      if(this.radioFiltro==1) {
        this.musics = this.musicasSemFiltro;
      } else if (this.radioFiltro==2) {
        this.musics = this.musicasSemFiltro.filter(finalizados => finalizados.status.name=='Aguardando Aprovação')
      } else {
        this.musics = this.musicasSemFiltro.filter(finalizados => finalizados.status.name=='Disponível')
      }
    },
      filterByName() {
          var filter, table, tr, td, i, txtValue;
          filter = this.filtroPorNome.toUpperCase();
          table = document.getElementById("tabelaMusicas");
          tr = table.getElementsByTagName("tr");
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
              txtValue = td.textContent || td.innerText;
              if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
              } else {
                tr[i].style.display = "none";
              }
            }       
          }
      }
  },
  components: {
    ModalPix,
  }
};
</script>

<style scoped>

.btn-text {
    cursor: pointer;
    text-decoration: underline;
    transition: .5s;
}

.btn-text:hover {
    color: #01E769;
}

input[type="radio"]:checked {
  accent-color: #01e769;
}

#searchTable{
  background: rgba(128, 128, 128, 0.589) !important;
  color: white !important;
  border: 2px solid #01e769 !important;
  border-radius: 0 5px 5px 0;
}

.icon-search{
  color: white;
  background: rgba(128, 128, 128, 0.589) !important;
  border: 2px solid #01e769 !important;
}

::placeholder {
  color: white !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: white !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 2px solid lightgray;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px rgba(128, 128, 128, 0.589) inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>